import React from 'react'

import Svg, { IconProps } from './Svg'

export const CloseIcon = (props: IconProps) => {
  return (
    <Svg {...props}>
      <path
        d="M6.69708 4.57538L4.57576 6.6967L9.87906 12L4.57576 17.3033L6.69708 19.4246L12.0004 14.1213L17.3037 19.4246L19.425 17.3033L14.1217 12L19.425 6.6967L17.3037 4.57538L12.0004 9.87868L6.69708 4.57538Z"
        fill="currentColor"
      />
    </Svg>
  )
}
