import { HttpClient } from '@binance/w3w-http-client'
import { SignClient } from '@binance/w3w-sign-client'
import { IRpcConfig, IWCEthRpcConnectionOptions } from '@binance/w3w-types'
import {
  log,
  signingMethods,
  utf8ToHex,
  getRpcUrl,
  formatJsonRpcRequest,
  isJsonRpcSuccess,
  isInBinance,
  normalizeChainId,
} from '@binance/w3w-utils'
import {
  IEthereumProvider,
  ProviderAccounts,
  RequestArguments,
} from 'eip1193-provider'
import EventEmitter from 'eventemitter3'

class BinanceW3WProvider implements IEthereumProvider {
  public events: any = new EventEmitter()

  private signClient: SignClient
  private rpc: IRpcConfig
  private httpClient: HttpClient
  private optsChainId: number
  private lng: string

  constructor(opts?: IWCEthRpcConnectionOptions) {
    this.rpc = { infuraId: opts?.infuraId, custom: opts?.rpc }
    this.optsChainId = opts?.chainId || 56
    this.lng = opts?.lng || 'en'

    this.signClient = new SignClient()
    this.registerEventListeners()
    this.httpClient = this.setHttpProvider(this.optsChainId)
  }

  get connected(): boolean {
    return this.signClient.connected
  }

  get connector(): SignClient {
    return this.signClient
  }

  get accounts(): string[] {
    return this.signClient.accounts
  }

  get chainId(): string {
    log.debug('provider get chainId', this.signClient.chainId)
    return this.signClient.chainId
  }
  get rpcUrl(): string {
    return this.httpClient.url || ''
  }

  public async request(args: RequestArguments) {
    log.debug('ethereum-provider request', args)
    switch (args.method) {
      case 'eth_requestAccounts':
        await this.connect()
        return this.accounts
      case 'eth_chainId':
        return this.chainId
      case 'eth_accounts':
        return this.accounts
      default:
        break
    }
    const requestArgs = formatJsonRpcRequest(args.method, args.params || [])
    if (signingMethods.includes(args.method)) {
      // Remove any
      return this.signClient.request<any>(requestArgs)
    }
    if (typeof this.httpClient === 'undefined') {
      throw new Error(
        `Cannot request JSON-RPC method (${args.method}) without provided rpc url`
      )
    }
    const res = await this.httpClient.request(requestArgs)
    if (isJsonRpcSuccess(res)) {
      return res.result
    } else {
      throw new Error(res.error.message)
    }
  }

  public async signMessage(message: string): Promise<string> {
    log.debug('signMessage', message)
    if (!this.accounts.length) {
      await this.enable()
    }
    return await this.request({
      method: 'personal_sign',
      params: [utf8ToHex(message), this.accounts[0]],
    })
  }

  public sendAsync(
    args: RequestArguments,
    callback: (error: Error | null, response: any) => void
  ): void {
    this.request(args)
      .then((response) => callback(null, response))
      .catch((error) => callback(error, undefined))
  }
  public setLng(lng: string): void {
    this.lng = lng
  }

  public async enable(chainId?: number): Promise<ProviderAccounts> {
    await this.connect(chainId)
    return this.accounts
  }

  private async connect(chainId?: number): Promise<void> {
    if (!this.connected) {
      await this.signClient.open({
        requestChainId: chainId?.toString() ?? this.optsChainId.toString(),
        lng: this.lng,
      })
    } else {
      log.info('already connected')
    }
  }

  public disconnect() {
    if (this.connected) {
      this.signClient.disconnect()
    }
  }

  public on(event: any, listener: any): void {
    this.events.on(event, listener)
  }
  public once(event: string, listener: any): void {
    this.events.once(event, listener)
  }
  public removeListener(event: string, listener: any): void {
    this.events.removeListener(event, listener)
  }
  public off(event: string, listener: any): void {
    this.events.off(event, listener)
  }

  get isWalletConnect() {
    return true
  }

  // ---------- Private ----------------------------------------------- //

  private registerEventListeners() {
    this.signClient.on('accountsChanged', (accounts) => {
      this.events.emit('accountsChanged', accounts)
    })
    this.signClient.on('chainChanged', (chainId) => {
      this.httpClient = this.setHttpProvider(normalizeChainId(chainId))
      this.events.emit('chainChanged', chainId)
    })
    this.signClient.on('disconnect', () => {
      this.events.emit('disconnect')
    })
  }
  private setHttpProvider(chainId: number): HttpClient | undefined {
    const rpcUrl = getRpcUrl(chainId, this.rpc)
    if (typeof rpcUrl === 'undefined') return undefined
    return new HttpClient(rpcUrl)
  }
}

export const getProvider = (
  opts?: IWCEthRpcConnectionOptions
): BinanceW3WProvider => {
  const isBinance = isInBinance()
  if (isBinance) {
    const provider =
      typeof window !== 'undefined' ? (window as any).ethereum : undefined
    if (provider) {
      provider.setLng = () => {}
      provider.disconnect = () => {}
      return provider as BinanceW3WProvider
    }
  }
  return new BinanceW3WProvider(opts)
}
export default BinanceW3WProvider
