import React from 'react'

import { logoImg } from '../constants'
import useT from '../hooks/useT'
import { CloseIcon } from '../icons/Close'

export const MobileModal = ({ id, onClose, onConnect }) => {
  const t = useT()
  return (
    <div
      id={id}
      className="animated fadeIn pointer-events-auto fixed top-0 left-0 h-full w-full bg-black/[.80] duration-300 ease-in-out will-change-auto"
    >
      <div className="absolute bottom-0 m-auto w-full rounded-t-2xl bg-white px-4 pb-6 shadow-inner duration-300 ease-in-out will-change-transform md:w-[400px]">
        <Header onClose={onClose} />
        <div className="mt-6 mb-4 flex justify-center">
          <img className="w-[60px]" src={logoImg} />
        </div>
        <div className="subtitle1 text-center mb-6 t-primary">
          {t('sdk-modal-title', { default: 'Connect with Binance' })}
        </div>
        <button
          onClick={onConnect}
          style={{
            borderColor: 'transparent',
          }}
          className="w-full rounded h-[40px] flex justify-center items-center bg-primary t-primary subtitle3 mb-4"
        >
          {t('sdk-connect', { default: 'Connect' })}
        </button>
        <div className="text-center py-3">
          <span>
            {t('sdk-no-app', { default: 'Don’t have Binance app yet?' })}
          </span>
          <a
            target="_blank"
            href="https://www.binance.com/en/download"
            className="t-brand ml-1"
          >
            {t('sdk-install', { default: 'Install' })}
          </a>
        </div>
      </div>
    </div>
  )
}

const Header = ({ onClose }) => (
  <div className="flex items-center justify-end h-[52px]">
    <div className="cursor-pointer text-[#929AA5]" onClick={onClose}>
      <CloseIcon className="w-[20px]" />
    </div>
  </div>
)
