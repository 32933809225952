import { SUPPORTED_LNGS } from './constants.mjs'
import languagesData from './data'

const languages: { [lang: string]: any } = SUPPORTED_LNGS.reduce(
  (acc: any, lang: string) => {
    acc[lang] = languagesData[lang]
    return acc
  },
  {}
)

export default languages
