import { detectWebSocket } from '@binance/w3w-socket-transport'
import {
  isNode,
  setStorage,
  getStorage,
  removeStorage,
} from '@binance/w3w-utils'
import { Interface } from '@ethersproject/abi'
import axios from 'axios'
import { decode } from 'js-base64'

import { BSC_RPCS, DOMAINS_KEY, DEFAULT_RELAY } from './constants'

type RpcResponse = {
  id: string
  jsonrpc: '2.0'
  result: string
}

async function getDomains() {
  const res = await Promise.race(
    BSC_RPCS.map((url) =>
      axios.request<any, { data: RpcResponse }>({
        url: url,
        method: 'POST',
        data: {
          jsonrpc: '2.0',
          id: Date.now(),
          method: 'eth_call',
          params: [
            {
              to: '0x76054B318785b588A3164B2A6eA5476F7cBA51e0',
              data: '0x97b5f450',
            },
            'latest',
          ],
        },
      })
    )
  )
  const itf = new Interface(['function apiDomains() view returns (string)'])
  const domains = decode(
    itf.decodeFunctionResult('apiDomains', res.data.result)[0]
  )

  return domains.split(',')
}

function sortWebSocketByTtl(domains: { url: string; ttl: number }[]) {
  return domains
    .filter((item) => item.ttl > 0)
    .sort((a, b) => a.ttl - b.ttl)
    .map((item) => item.url)
}

/**
 * Retrieves all the domains of the connections.
 *
 * @return {Array} sortedDomains
 */
async function retrieveConnectionDomains(): Promise<string[]> {
  const domains = await getDomains()
  const domainsWithTtl = await Promise.all(
    domains.map((domain) => {
      const subDomain = domain.split('.').slice(1).join('.')
      return detectWebSocket(`wss://nbstream.${subDomain}/wallet-connector`)
    })
  )
  const sortedDomains = sortWebSocketByTtl(domainsWithTtl)

  return sortedDomains
}

// retrieves eligible connection urls immediately
let retrivePromise = Promise.resolve([])

if (!isNode()) {
  const relaysFromStorage = getStorage<string[]>(DOMAINS_KEY)
  retrivePromise = Promise.resolve(relaysFromStorage)
  if (!relaysFromStorage || relaysFromStorage.length === 0) {
    retrivePromise = retrieveConnectionDomains()
      .then((domains) => {
        console.log('🚀 ~ file: relay.ts:63 ~ .then ~ domains:', domains)
        setStorage(DOMAINS_KEY, domains)
        return domains
      })
      .catch(() => {
        return [] as string[]
      })
  }
}

export async function getEligibleConnectionRelays(): Promise<string[]> {
  const relays = await retrivePromise

  if (relays.length === 0) {
    // pod default relay
    relays.push(DEFAULT_RELAY)
  }

  return relays
}

export function removeRelayFromStorage(relay: string) {
  const relays = getStorage<string[]>(DOMAINS_KEY)
  if (!relays) return

  const newRelays = relays.filter((item) => item !== relay)
  setStorage(DOMAINS_KEY, newRelays)
}

export function clearRelaysFromStorage() {
  removeStorage(DOMAINS_KEY)
}
