const languageDetector = {
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
  lookupQuerystring: function () {
    const match = window.location.search.match(/lng=([^&]*)/)
    return match && match[1]
  },
  lookupCookie: function () {
    const match = document.cookie.match(/i18next=([^;]*)/)
    return match && match[1]
  },
  lookupLocalStorage: function () {
    return localStorage.getItem('i18nextLng')
  },
  lookupSessionStorage: function () {
    return sessionStorage.getItem('i18nextLng')
  },
  lookupFromNavigator: function () {
    return navigator.language
  },
  lookupFromHtmlTag: function () {
    return document.documentElement.lang
  },
  lookupFromPath: function () {
    const match = window.location.pathname.match(/\/([^/]*)/)
    return match && match[1]
  },
  lookupFromSubdomain: function () {
    const match = window.location.hostname.match(/^(.+)\./)
    return match && match[1]
  },
}

export const detectLanguage = () => {
  for (const method of languageDetector.order) {
    try {
      const detectedLanguage =
        languageDetector[
          'lookup' + method.charAt(0).toUpperCase() + method.slice(1)
        ]()
      if (detectedLanguage) {
        return detectedLanguage
      }
    } catch (error) {
      console.error(`Error detecting language with method: ${method}`, error)
      continue
    }
  }
  return 'en'
}
