import React from 'react'

import Svg, { IconProps } from './Svg'

export const ScanF = (props: IconProps) => {
  return (
    <Svg size={24} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 4H10.5V7H7.5V10H4.5V7V4H7.5ZM14.5 4H17.5H20.5V7V10H17.5V7H14.5V4ZM10.5 20V17H7.5V14H4.5V17V20H7.5H10.5ZM17.5 20H14.5V17H17.5V14H20.5V17V20H17.5ZM16.5 10.5H8.5V13.5H16.5V10.5Z"
        fill="#202630"
      />
    </Svg>
  )
}
