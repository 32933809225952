import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { BinanceW3W_STYLE_SHEET } from './assets/style'
import { LanguageContext } from './components/Context'
import Modal from './components/Modal'
import {
  ANIMATION_DURATION,
  BinanceW3W_WRAPPER_ID,
  BinanceW3W_MODAL_ID,
  BinanceW3W_STYLE_ID,
} from './constants'
import { detectLanguage } from './languages/detector'

function injectStyleSheet() {
  const doc = window.document
  const prev = doc.getElementById(BinanceW3W_STYLE_ID)
  if (prev) {
    doc.head.removeChild(prev)
  }
  const style = doc.createElement('style')
  style.setAttribute('id', BinanceW3W_STYLE_ID)
  style.innerHTML = BinanceW3W_STYLE_SHEET
  doc.head.appendChild(style)
}

function renderWrapper(): HTMLDivElement {
  const doc = window.document
  const wrapper = doc.createElement('div')
  wrapper.setAttribute('id', BinanceW3W_WRAPPER_ID)
  doc.body.appendChild(wrapper)
  return wrapper
}

function triggerCloseAnimation(): void {
  const doc = window.document
  const modal = doc.getElementById(BinanceW3W_MODAL_ID)
  if (modal) {
    modal.className = modal.className.replace('fadeIn', 'fadeOut')
    setTimeout(() => {
      const wrapper = doc.getElementById(BinanceW3W_WRAPPER_ID)
      if (wrapper) {
        doc.body.removeChild(wrapper)
      }
    }, ANIMATION_DURATION)
  }
}

function getWrappedCallback(cb: () => void): () => void {
  return () => {
    triggerCloseAnimation()
    if (cb) {
      cb()
    }
  }
}

export async function open({
  isReady,
  cb,
  lng,
}: {
  isReady: Promise<string>
  cb: () => void
  lng?: string
}) {
  // injectStyleSheet()
  const wrapper = renderWrapper()
  const root = createRoot(wrapper)
  await isReady
  const currLng = lng ?? detectLanguage()
  root.render(
    <LanguageContext.Provider value={{ lng: currLng }}>
      <style
        dangerouslySetInnerHTML={{
          __html: BinanceW3W_STYLE_SHEET,
        }}
      />
      <Modal isReady={isReady} onClose={getWrappedCallback(cb)} />
    </LanguageContext.Provider>
  )
}

export function close() {
  triggerCloseAnimation()
}
