import { QRCodeSVG } from 'qrcode.react'
import React from 'react'

import { logoImg } from '../constants'
import { useBinanceDeepLink } from '../hooks/useDeepLink'

interface QRCodeDisplayProps {
  url: string
}

function QRCodeDisplay(props: QRCodeDisplayProps) {
  const { toBinance } = useBinanceDeepLink(props.url)

  return (
    <div>
      <div className="mt-[35px] flex justify-center">
        <div className={`w-[200px] h-[200px] mb-4`} onClick={toBinance}>
          {props.url && (
            <QRCodeSVG
              value={props.url}
              width="100%"
              height="100%"
              level="M"
              imageSettings={{
                src: logoImg,
                height: 32,
                width: 32,
                excavate: false,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default QRCodeDisplay
