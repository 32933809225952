import React from 'react'

import Svg, { IconProps } from './Svg'

export const GooglePlayF = (props: IconProps) => {
  return (
    <Svg size={24} {...props}>
      <path
        d="M13.5589 11.0874L4.08203 1.59644H4.17441C4.98558 1.59644 5.68614 1.89129 6.81073 2.4993L16.7488 7.88083L13.5589 11.0874Z"
        fill="#202630"
      />
      <path
        d="M12.6373 12.008L2.90218 21.7203C2.66236 21.3329 2.49658 20.7063 2.49658 19.8034V4.19354C2.49658 3.29078 2.66236 2.66403 2.90218 2.2771L12.6373 12.008Z"
        fill="#202630"
      />
      <path
        d="M13.5589 12.9124L16.7488 16.1187L6.81073 21.5001C5.68614 22.1083 4.98548 22.4036 4.17441 22.4036H4.08203L13.5589 12.9124Z"
        fill="#202630"
      />
      <path
        d="M17.9437 8.52563L14.4775 12.0091L17.9437 15.4738L20.0456 14.3309C20.8199 13.9069 22 13.1329 22 12.0091C22 10.8662 20.8199 10.0922 20.0456 9.66821L17.9437 8.52563Z"
        fill="#202630"
      />
    </Svg>
  )
}
