import { openBinanceDeepLink } from '@binance/w3w-utils'
import React, { useEffect, useState } from 'react'

import { BinanceW3W_MODAL_ID } from '../constants'
import { useIsMobile } from '../hooks/useIsMobile'

import { Footer } from './Footer'
import Header from './Header'
import { MobileModal } from './MobileModal'
import QRCodeDisplay from './QRCodeDisplay'

interface ModalProps {
  onClose: () => void
  isReady: Promise<string>
}

const Modal: React.FC<ModalProps> = ({ onClose, isReady }: ModalProps) => {
  const { isMobile } = useIsMobile()
  const [url, setUrl] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    isReady.then(setUrl).catch(() => setError(true))
  }, [isReady])

  const onConnect = () => {
    openBinanceDeepLink(url)
  }

  const displayProps = { url, error }

  if (isMobile === false) {
    return (
      <div
        id={BinanceW3W_MODAL_ID}
        className="animated fadeIn pointer-events-auto fixed top-0 left-0 h-full w-full bg-black/[.80] duration-300 ease-in-out will-change-auto"
      >
        <div className="relative top-2/4 m-auto w-[343px] translate-y-[-50%] rounded-2xl bg-white px-6 pt-[20px] pb-6 shadow-inner duration-300 ease-in-out will-change-transform md:w-[400px] lg:p-8 lg:pt-6">
          <Header onClose={onClose} />
          <QRCodeDisplay {...displayProps} />
          <Footer />
        </div>
      </div>
    )
  }
  if (isMobile) {
    return (
      <MobileModal
        onConnect={onConnect}
        onClose={onClose}
        id={BinanceW3W_MODAL_ID}
      />
    )
  }
}

export default Modal
