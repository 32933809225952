import React from 'react'

export interface IconProps {
  size?: number
  color?: string
  className?: string
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<SVGElement>
}

interface SvgProps extends IconProps {
  children: React.ReactNode
}

const Svg: React.FC<SvgProps> = ({
  size = 24,
  color = 'currentColor',
  className,
  children,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
    </svg>
  )
}

export default Svg
