import { useCallback } from 'react'

import { useLanguageContext } from '../components/Context'
import i18n from '../languages'

const useT = () => {
  const { lng } = useLanguageContext()
  return useCallback(
    (i18nKey: string, params?: { default?: string }) =>
      i18n?.[lng]?.[i18nKey] ||
      i18n?.en?.[i18nKey] ||
      params?.default ||
      i18nKey,
    [lng]
  )
}

export default useT
