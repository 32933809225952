import { getIsAndroid, getIsMobile } from '@binance/w3w-utils'
import { useEffect, useState } from 'react'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>()
  const [isAndroid, setAndroid] = useState(false)
  useEffect(() => {
    const _isMobile = getIsMobile()
    isAndroid
    const android = getIsAndroid()
    setIsMobile(_isMobile)
    setAndroid(android)
  }, [])
  return { isMobile, isAndroid }
}
