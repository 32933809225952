import ar from './ar.json'
import bg_BG from './bg-BG.json'
import cs_CZ from './cs-CZ.json'
import da_DK from './da-DK.json'
import de_DE from './de-DE.json'
import el_GR from './el-GR.json'
import en from './en.json'
import es_ES from './es-ES.json'
import es_LA from './es-LA.json'
import fr_FR from './fr-FR.json'
import id_ID from './id-ID.json'
import it_IT from './it-IT.json'
import kk_KZ from './kk-KZ.json'
import lo_LA from './lo-LA.json'
import lv_LV from './lv-LV.json'
import my_MM from './my-MM.json'
import pl_PL from './pl-PL.json'
import pt_BR from './pt-BR.json'
import pt_PT from './pt-PT.json'
import ro_RO from './ro-RO.json'
import ru_RU from './ru-RU.json'
import si_LK from './si-LK.json'
import sk_SK from './sk-SK.json'
import sl_SI from './sl-SI.json'
import sv_SE from './sv-SE.json'
import tr_TR from './tr-TR.json'
import uk_UA from './uk-UA.json'
import vi_VN from './vi-VN.json'
import zh_CN from './zh-CN.json'
import zh_TW from './zh-TW.json'

export default {
  en,
  ar,
  'bg-BG': bg_BG,
  'zh-CN': zh_CN,
  'zh-TW': zh_TW,
  'cs-CZ': cs_CZ,
  'fr-FR': fr_FR,
  'de-DE': de_DE,
  'el-GR': el_GR,
  'id-ID': id_ID,
  'it-IT': it_IT,
  'kk-KZ': kk_KZ,
  'lv-LV': lv_LV,
  'pl-PL': pl_PL,
  'pt-BR': pt_BR,
  'pt-PT': pt_PT,
  'ro-RO': ro_RO,
  'ru-RU': ru_RU,
  'sk-SK': sk_SK,
  'sl-SI': sl_SI,
  'es-LA': es_LA,
  'es-ES': es_ES,
  'sv-SE': sv_SE,
  'tr-TR': tr_TR,
  'uk-UA': uk_UA,
  'vi-VN': vi_VN,
  'da-DK': da_DK,
  'my-MM': my_MM,
  'lo-LA': lo_LA,
  'si-LK': si_LK,
}
