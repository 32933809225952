import { getHref } from '@binance/w3w-utils'

import { useIsMobile } from './useIsMobile'

const delayRun = (callback: () => void, timeout: number) => {
  const event = 'visibilitychange'

  const timeoutTask = setTimeout(() => {
    if (!document.hidden) {
      callback()
    }
  }, timeout)

  const logic = () => {
    if (document.hidden) {
      clearTimeout(timeoutTask)
      document.removeEventListener(event, logic)
    }
  }
  document.addEventListener(event, logic, false)
}

const wakeUpOrInstallAndroid = (href: string) => {
  const a = document.createElement('a')
  a.href = href
  document.body.appendChild(a)
  delayRun(() => {
    window.location.href = 'https://www.binance.com/en/download'
  }, 1000)
  a.click()
  document.body.removeChild(a)
}

export const useBinanceDeepLink = (wc: string) => {
  const { isAndroid, isMobile } = useIsMobile()
  const toBinance = () => {
    const href = getHref(isAndroid, wc)
    if (!isMobile) return
    if (!isAndroid) {
      const a = document.createElement('a')
      a.href = href
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    if (isAndroid) wakeUpOrInstallAndroid(href)
  }
  return { toBinance }
}
