export const SUPPORTED_LNGS = [
  'en',
  'ar',
  'bg-BG',
  'zh-CN',
  'zh-TW',
  'cs-CZ',
  'fr-FR',
  'de-DE',
  'el-GR',
  'id-ID',
  'it-IT',
  'kk-KZ',
  'lv-LV',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'es-LA',
  'es-ES',
  'sv-SE',
  'tr-TR',
  'uk-UA',
  'vi-VN',
  'da-DK',
  'my-MM',
  'lo-LA',
  'si-LK',
]