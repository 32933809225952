import React from 'react'

import useT from '../hooks/useT'
import { CloseIcon } from '../icons/Close'

interface HeaderProps {
  onClose: () => void
}

const Header: React.FC<HeaderProps> = ({ onClose }) => {
  const t = useT()
  return (
    <div className="flex items-center justify-between">
      <p className="text-base font-medium text-[#1E2329] lg:text-xl md:font-semibold md:subtitle1">
        {t('sdk-modal-title', { default: 'Connect with Binance' })}
      </p>
      <div className="cursor-pointer text-[#929AA5]" onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default Header
