export const baseStyle = `.pointer-events-auto {
  pointer-events: auto;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.top-0 {
  top: 0px;
}

.top-2\\/4 {
  top: 50%;
}

.m-auto {
  margin: auto;
}

.mx-\\[4px\\] {
  margin-left: 4px;
  margin-right: 4px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\\[35px\\] {
  margin-top: 35px;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-\\[200px\\] {
  height: 200px;
}

.h-\\[24px\\] {
  height: 24px;
}

.h-\\[40px\\] {
  height: 40px;
}

.h-\\[52px\\] {
  height: 52px;
}

.h-full {
  height: 100%;
}

.w-\\[150px\\] {
  width: 150px;
}

.w-\\[200px\\] {
  width: 200px;
}

.w-\\[20px\\] {
  width: 20px;
}

.w-\\[24px\\] {
  width: 24px;
}

.w-\\[343px\\] {
  width: 343px;
}

.w-\\[60px\\] {
  width: 60px;
}

.w-\\[75px\\] {
  width: 75px;
}

.w-full {
  width: 100%;
}

.translate-y-\\[-50\\%\\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.bg-black\\/\\[\\.80\\] {
  background-color: rgb(0 0 0 / .80);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-\\[12px\\] {
  padding: 12px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-\\[20px\\] {
  padding-top: 20px;
}

.text-center {
  text-align: center;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-medium {
  font-weight: 500;
}

.text-\\[\\#1E2329\\] {
  --tw-text-opacity: 1;
  color: rgb(30 35 41 / var(--tw-text-opacity));
}

.text-\\[\\#929AA5\\] {
  --tw-text-opacity: 1;
  color: rgb(146 154 165 / var(--tw-text-opacity));
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.will-change-auto {
  will-change: auto;
}

.will-change-transform {
  will-change: transform;
}

.body3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.subtitle1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.subtitle3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.caption2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.t-black {
  color: #0b0e11;
}

.t-brand {
  color: #c99400;
}

.t-primary {
  color: #202630;
}

.t-secondary {
  color: #474d57;
}

.bg-primary {
  background: #fcd535;
}

@media (min-width: 768px) {
  .md\\:subtitle1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .md\\:w-\\[400px\\] {
    width: 400px;
  }

  .md\\:font-semibold {
    font-weight: 600;
  }
}

@media (min-width: 1024px) {
  .lg\\:p-8 {
    padding: 2rem;
  }

  .lg\\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
`