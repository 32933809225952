import React from 'react'

import { DOWNLOAD_URLS } from '../constants'
import useT from '../hooks/useT'
import { AppleF } from '../icons/Apple'
import { GooglePlayF } from '../icons/GooglePlay'
import { ScanF } from '../icons/ScanF'

import { Trans } from './Trans'

export const Footer = () => {
  const t = useT()
  return (
    <>
      <Instructions t={t} />
      <DownloadPrompt t={t} />
      <DownloadOptions />
    </>
  )
}

const Instructions = ({ t }) => (
  <div
    style={{
      borderBottom: '1px solid #EAECEF',
    }}
    className="grid justify-center gap-y-2 pb-6 body3 t-black border-b border-gray-300"
  >
    <div>{t('sdk-modal-instruct-1', { default: '1. Open Binance app' })}</div>
    <div className="flex items-center">
      <Trans
        value={t('sdk-modal-instruct-2', {
          default: '2. Tap {{icon}} on Home',
        })}
        icon={<ScanF className="w-[24px] h-[24px] mx-[4px]" />}
      />
    </div>
  </div>
)

const DownloadPrompt = ({ t }) => (
  <div className="py-4 body3 t-secondary text-center">
    {t('sdk-no-app', { default: `Don't have Binance app yet?` })}
  </div>
)

const DownloadOptions = () => (
  <div className="grid grid-cols-2 gap-x-4">
    <Download type="iOS" />
    <Download type="Android" />
  </div>
)

const Download = ({ type }: { type: 'Android' | 'iOS' }) => {
  const t = useT()
  const onClick = () => {
    window.open(
      type === 'Android' ? DOWNLOAD_URLS.googlePlay : DOWNLOAD_URLS.appleStore,
      '_blank'
    )
  }
  return (
    <div
      style={{
        border: '1px solid #EAECEF',
      }}
      className="p-[12px] rounded-lg grid cursor-pointer t-secondary grid-flow-col items-center gap-x-4 w-[150px]"
      onClick={onClick}
    >
      {type === 'Android' ? (
        <GooglePlayF className="w-[24px] h-[24px] m-auto" />
      ) : (
        <AppleF className="w-[24px] h-[24px] m-auto" />
      )}
      <div className="w-[75px] caption2 t-secondary">
        {t(`sdk-download-${type.toLowerCase()}`, {
          default: `Download for ${type}`,
        })}
      </div>
    </div>
  )
}
