import React from 'react'

export const Trans = ({ value, ...props }) => {
  const stringArray = parseStringToArray(value)
  const result = stringArray.map((item) =>
    typeof item === 'string'
      ? item
      : React.cloneElement(props[item.key], { key: item.key })
  )
  return <>{result}</>
}

// parse string "2. Tab {{icon}} on Home" to array
// ["2. Tab ",{key: icon}," on Home"]

const parseStringToArray = (str) => {
  const regex = /{{(.*?)}}/g
  let match
  let lastIndex = 0
  const result = []

  while ((match = regex.exec(str)) !== null) {
    if (match.index !== lastIndex) {
      result.push(str.substring(lastIndex, match.index))
    }

    result.push({ key: match[1] })
    lastIndex = regex.lastIndex
  }

  if (lastIndex !== str.length) {
    result.push(str.substring(lastIndex))
  }

  return result
}
